import React, { forwardRef, useRef } from "react";
import styled from "styled-components";

import RequestButton from "@components/shared/RequestButton";

import {
  fontSize,
  fontWeight,
  onlyMobiles,
  onlyTabletAndDesktop,
  prop,
  color
} from "@components/shared/utils";
import { string, bool, number } from "prop-types";
import { useIsStickyTopOrBottom } from "@hooks/useIsStickyTopOrBottom";
import { usePrimaryColor } from "@hooks/usePrimaryColor";
import { useDetectDevice } from "@hooks/useDetectDevice";

const Positioner = styled("div")`
  ${onlyTabletAndDesktop`
    display: none;
  `}
`;

const Wrapper = styled("div")`
  padding: 0.5rem;
  background-color: ${color("white")};
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: ${prop("boxShadow")};
  -moz-box-shadow: ${prop("boxShadow")};
  box-shadow: ${prop("boxShadow")};

  ${onlyMobiles`
    ${({
      stickyStyles,
      stickToTop,
      stickToBottom,
      fixedHeaderHeight,
      fixedFooterHeight,
    }) =>
      (stickToTop || stickToBottom) &&
      `
        position: fixed;
        top: ${stickToTop ? fixedHeaderHeight.toString() + "px" : "unset"};
        bottom: ${
          stickToBottom ? fixedFooterHeight.toString() + "px" : "unset"
        };
        width: 100%;
        margin: 0;
        border-radius: 0;
        z-index: 999;
        ${stickyStyles}
    `}
  `}
`;

const StyledRequestButton = styled(RequestButton)`
  font-weight: ${fontWeight("bold")};
  font-size: ${fontSize("md")};
  margin: ${prop("margin")};
  justify-content: center;
  align-items: center;
  background-color: ${prop("backgroundColor")};
  color: ${prop("color")};
  width: ${prop("width")};
`;

const StickyRequestButton = forwardRef(
  (
    {
      stickyStyles = "",
      isStickyToTop = false,
      isStickyToBottom = false,
      fixedHeaderHeight = 0,
      fixedFooterHeight = 0,
      ...props
    },
    ref
  ) => {
    const positionerRef = useRef(null);

    const { isMobile } = useDetectDevice();

    const { stickToTop, stickToBottom } = useIsStickyTopOrBottom(
      isStickyToTop,
      isStickyToBottom,
      positionerRef,
      fixedHeaderHeight
    );

    const primaryColor = usePrimaryColor();

    const isStickyButton = isMobile && (stickToBottom || stickToTop);

    const ButtonContent = (
      <StyledRequestButton
        ref={ref}
        backgroundColor={isStickyButton ? primaryColor : color("white")}
        color={isStickyButton ? color("white") : primaryColor}
        margin={isStickyButton ? 0 : '1rem auto'}
        width={isStickyButton ? '90%' : undefined}
        {...props}
      />
    );

    return (
      <>
        <Positioner ref={positionerRef} />
        {isStickyButton ?
          <Wrapper
            stickyStyles={stickyStyles}
            stickToTop={isStickyToTop && stickToTop}
            stickToBottom={isStickyToBottom && stickToBottom}
            fixedHeaderHeight={fixedHeaderHeight}
            fixedFooterHeight={fixedFooterHeight}
            boxShadow="0 0 5px rgba(0, 0, 0, 0.08), 0 0 10px rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 0, 0.15)"
          >
            {ButtonContent}
          </Wrapper>
          :
          ButtonContent
        }
      </>
    );
  }
);

StickyRequestButton.displayName = "StickyRequestButton";

StickyRequestButton.propTypes = {
  stickyStyles: string,
  isStickyToTop: bool,
  isStickyToBottom: bool,
  fixedHeaderHeight: number,
  fixedFooterHeight: number,
};

export default StickyRequestButton;
